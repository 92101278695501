.toggle-switch {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 10px; /* Spacing between elements */
  }
  
  .switch {
    position: relative;
    width: 40px; /* Width of the toggle */
    height: 24px; /* Height of the toggle */
    display: flex;
    align-items: center; /* Aligns the slider vertically */
  }
  
  .switch input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2; /* Ensures the input is above the slider for clicking */
    cursor: pointer;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
    z-index: 1; /* Ensures slider is behind the input */
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(16px);
  }
  
  .label {
    font-size: 16px;
    color: #666;
  }
  