@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

h2 {
    font-weight: 500;
    font-size: 1rem;
}

.formik-button {
    background: blue;
}

.profile-photo-container {
    flex-shrink: 0; /* Prevents the photo from being squished */
    width: 4rem; /* 64px */
    height: 4rem; /* 64px */
}

.profile-photo-container img {
    width: 100%; /* Ensures the image fills the container */
    height: 100%; /* Ensures the image fills the container */
    object-fit: cover; /* Maintains aspect ratio */
    border-radius: 50%; /* Makes the image round */
}

.ad-container {
    min-width: 320px; 
    width: 100%;
    text-align: 'center';
    margin: '0 auto';
  }

  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .truncate-multi-line {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines */
    -webkit-box-orient: vertical;
  }
  
  @media screen and (max-width: 768px) {
    .truncate-multi-line {
      -webkit-line-clamp: 2; /* Fewer lines on smaller screens */
    }
  }

  /* QUILL RENDERED CONTENT  */

.prose h1,
.prose h2,
.prose h3,
.prose h4,
.prose h5,
.prose h6 {
    color: #333; /* Dark grey for better contrast */
    margin-top: 0.8em;
    margin-bottom: 0.4em;
}

.prose h1 {
    font-size: 1.8em; /* Larger font size for h1 */
}

.prose h2 {
    font-size: 1.6em; /* Smaller than h1 but still significant */
}

.prose h3 {
    font-size: 1.4em;
}

.prose p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    line-height: 1.6; /* More readable line height */
    font-size: 1em; /* Standard font size for paragraph text */
    color: #444; /* Slightly lighter than headers for distinction */
}

.prose ul {
    list-style-type: disc; /* Bullets for unordered lists */
    margin-left: 20px; /* Indent list items */
}

.prose ol {
    list-style-type: decimal; /* Numbers for ordered lists */
    margin-left: 20px; /* Indent list items */
}

.prose li {
    margin-top: 0.2em;
    margin-bottom: 0.2em;
    line-height: 1.6; /* Matching line height with paragraphs */
}

/* Scrollbar styling */
::-webkit-scrollbar {
    width: 10px; /* width of the entire scrollbar */
  }
  
  ::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area, you can also use Tailwind's bg-color classes */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #4a5568; /* color of the scroll thumb */
    border-radius: 6px; /* roundness of the scroll thumb */
  }
  